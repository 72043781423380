// @ts-nocheck
import React, { useContext } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import TourGuide from '../../tourGuide';
import Card, { CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from '../../../components/bootstrap/Card';
import FormGroup from '../../../components/bootstrap/forms/FormGroup';
import Button from '../../../components/bootstrap/Button';
import { useFormik } from 'formik';
import showNotification from '../../../components/extras/showNotification';
import Input from '../../../components/bootstrap/forms/Input';
import Label from '../../../components/bootstrap/forms/Label';

const ProfileSettingsPage = () => {
    const { userData, setUser } = useContext(AuthContext);
    const navigate = useNavigate();

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            current: "",
            newpassword: "",
            newpasswordcheck: "",
        },
        validate: (values) => {
            const errors: { current?: string; newpassword?: string; newpasswordcheck?: string } = {};

            if (!values.current) {
                errors.current = 'Required';
            }

            if (!values.newpassword) {
                errors.newpassword = 'Required';
            }

            if (values.newpassword && values.newpassword.length < 5) {
                errors.newpassword = 'Too short (min 5 characters)';
            }

            if (!values.newpasswordcheck) {
                errors.newpasswordcheck = 'Required';
            }

            if (values.newpasswordcheck && values.newpasswordcheck != values.newpassword) {
                errors.newpasswordcheck = 'New passwords do not match';
            }


            return errors;
        },
        validateOnChange: false,
        onSubmit: (values) => {
            fetch(`https://userserver.aiosbot.com/changePassword`, {
                method: "PUT",
                headers: {
                    'Content-Type': 'application/json',
                    authorization: userData.token
                },
                body: JSON.stringify({
                    old: formik.values.current,
                    new: formik.values.newpassword
                })
            }).then((res) => res.json())
                .then((data) => {
                    if (data.result == true) return showNotification("Saved", "New Password saved!", "success");
                    else return showNotification("Failed", "Current password incorrect!", "danger");
                }).catch(e => showNotification("Error", "Password could not be saved!", "danger"))
        },
    });

    return (
        <PageWrapper title='Profile Settings'>
            <TourGuide page='profile-settings' bclist={[{ title: "Profile-Settings", to: "/profile-settings" }]} />
            <Page>
                <div className='row'>
                    <div className="col-5">
                        <Card shadow={"md"} data-tour="changePassword">
                            <CardHeader size={"lg"}>
                                <CardLabel>
                                    <CardTitle>Change Password</CardTitle>
                                </CardLabel>
                            </CardHeader>
                            <CardBody>
                                <FormGroup size={"lg"}
									className='mb-3'>
                                    <Label>
                                        Current Password
                                    </Label>
                                    <Input
                                        id='current'
                                        type='password'
                                        placeholder='Current Password'
                                        autoComplete="current-password"
                                        value={formik.values.current}
                                        isTouched={formik.touched.current}
                                        invalidFeedback={
                                            formik.errors.current
                                        }
                                        isValid={formik.isValid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onFocus={() => {
                                            formik.setErrors({});
                                        }} />

                                </FormGroup>
                                <FormGroup size={"lg"}
									className='mb-3'>
                                    <Label>
                                        New Password
                                    </Label>
                                    <Input
                                        id='newpassword'
                                        placeholder='New Password'
                                        autoComplete="new-password"
                                        value={formik.values.newpassword}
                                        isTouched={formik.touched.newpassword}
                                        invalidFeedback={
                                            formik.errors.newpassword
                                        }
                                        isValid={formik.isValid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onFocus={() => {
                                            formik.setErrors({});
                                        }} />

                                </FormGroup>
                                <FormGroup size={"lg"}
									className='mb-3'>
                                    <Label>
                                        Repeat Password
                                    </Label>
                                    <Input
                                        id='newpasswordcheck'
                                        placeholder='Repeat new Password'
                                        autoComplete="new-password-repeat"
                                        value={formik.values.newpasswordcheck}
                                        isTouched={formik.touched.newpasswordcheck}
                                        invalidFeedback={
                                            formik.errors.newpasswordcheck
                                        }
                                        isValid={formik.isValid}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        onFocus={() => {
                                            formik.setErrors({});
                                        }} />

                                </FormGroup>
                            </CardBody>
                            <CardFooter>
                                <CardFooterRight>
                                    <Button color='primary' data-tour="SavePassword" onClick={formik.submitForm}>Change Password</Button>
                                </CardFooterRight>
                            </CardFooter>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default ProfileSettingsPage;
