import React, { FC, ReactNode, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useVerifyAuth from '../hooks/useVerifyAuth';

interface IAuthWrapperProps {
  children: ReactNode;
}

const AuthWrapper: FC<IAuthWrapperProps> = ({ children }) => {
  const location = useLocation();
  const verifyAuth = useVerifyAuth();

  useEffect(() => {
    const handleNavigation = async () => {
      // Skip verification for the login/public pages
      if (location.pathname === '/' || location.pathname.startsWith('/auth')) {
        return;
      }
      await verifyAuth();
    };

    handleNavigation();
  }, [location.pathname, verifyAuth]);

  return <>{children}</>;
};

export default AuthWrapper;
