import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import AuthContext from '../../../contexts/authContext';
import Spinner from '../../../components/bootstrap/Spinner';

const ManageSubscription = () => {
  const navigate = useNavigate();
  const { userData } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');

  const handleManageSubscription = async () => {
    if (!userData?.token || !userData?.bot?.id) {
      setError('No active subscription found');
      return;
    }

    setIsLoading(true);
    setError('');

    try {
      // Request Stripe portal session URL
      const response = await fetch('https://payment.aiosbot.com/create-portal-session', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          botId: userData.bot.id,
          userId: userData.id
        })
      });

      if (!response.ok) {
        throw new Error('Failed to create portal session');
      }

      const { url } = await response.json();
      
      // Redirect to Stripe Customer Portal
      window.location.href = url;
    } catch (err) {
      setError('Failed to access subscription management. Please try again later.');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <PageWrapper title='Manage Subscription'>
      <Page className='h-100'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 col-sm-10'>
            <Card>
              <CardBody className='p-5'>
                <div className='d-flex flex-column align-items-center'>
                  <h2 className='mb-4'>Manage Your Subscription</h2>
                  <p className='text-center mb-4'>
                    You will be redirected to the secure Stripe Customer Portal where you can:
                  </p>
                  <ul className='mb-4'>
                    <li>View your subscription details</li>
                    <li>Update your payment method</li>
                    <li>View billing history</li>
                    <li>Cancel or upgrade subscription</li>
                  </ul>
                  {error && (
                    <div className='alert alert-danger mb-4' role='alert'>
                      {error}
                    </div>
                  )}
                  <div className='d-flex gap-3 w-100'>
                    <Button
                      color='info'
                      className='w-100'
                      onClick={handleManageSubscription}
                      isDisable={isLoading}>
                      {isLoading ? (
                        <>
                          <Spinner isSmall inButton/>
                          <span className='ms-2'>Loading...</span>
                        </>
                      ) : (
                        'Manage Subscription'
                      )}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default ManageSubscription;
