import { useContext, useCallback } from 'react';
import AuthContext from '../contexts/authContext';

const useVerifyAuth = () => {
  const { user: token, setUser } = useContext(AuthContext);

  const verifyAuth = useCallback(async () => {
    if (!token) return false;

    try {
      const request = new XMLHttpRequest();
      request.open('GET', `https://userserver.aiosbot.com/verify`, false);
      request.setRequestHeader('authorization', token);
      request.send(null);

      if (request.status !== 200) {
        if (setUser) {
          setUser('');
        }
        sessionStorage.clear();
        localStorage.clear(); // This will clear everything including selectedBotName
        window.location.href = '/';
        return false;
      }

      return true;
    } catch (error) {
      if (setUser) {
        setUser('');
      }
      sessionStorage.clear();
      localStorage.clear(); // This will clear everything including selectedBotName
      window.location.href = '/';
      return false;
    }
  }, [token, setUser]);

  return verifyAuth;
};

export default useVerifyAuth;
