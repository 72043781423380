// @ts-nocheck
import React, { useContext, useEffect, useState } from 'react';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import AuthContext from '../../../contexts/authContext';
import { useNavigate } from 'react-router-dom';
import Card, { CardBody, CardHeader, CardTitle } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Label from '../../../components/bootstrap/forms/Label';
import Input from '../../../components/bootstrap/forms/Input';

const AiosLinkPage = () => {
    const { userData } = useContext(AuthContext);
    const navigate = useNavigate();
    const [data, setData] = useState({
        name: '',
        background: '',
        profilepicture: '',
        color: '',
        sitename: '',
        textcolor: ''
    });

    const botPremiumStatus = userData.bot ? userData.bot.premium : 0; // Extract premium status

    useEffect(() => {
        if (userData.token) {
            fetchAiosLinkData();
        }
    }, [userData]);

    const fetchAiosLinkData = () => {
        fetch('https://botapi.aiosbot.com/aioslink', {
            headers: {
                authorization: userData.token
            }
        })
            .then((res) => res.json())
            .then((result) => {
                setData(result[0]);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const css = `site-preview {
    --primary-color: #4c7bff;
    --background-color: rgba(10, 10, 10, 0.85);
    --card-background: rgba(26, 26, 26, 0.7);
    --text-color: #ffffff;
    --text-secondary: #a0a0a0;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

site-body {
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
    background-color: #0a0a0a;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    color: var(--text-color);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

main {
    flex: 1;
}

.container {
    max-width: 680px;
    margin: 0 auto;
    padding: 40px 20px;
}

.content-wrapper {
    background-color: rgba(32, 32, 32, 0.8); /* Default color if none set in database */
    border-radius: 20px;
    padding: 30px;
    backdrop-filter: blur(10px);
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease;
}

#profile-section {
    text-align: center;
    margin-bottom: 40px;
    padding: 20px;
}

.profile-picture {
    width: 120px;
    height: 120px;
    border-radius: 60px;
    margin: 0 auto 20px;
    display: block;
    object-fit: cover;
    border: 3px solid var(--primary-color); /* Default color if none set in database */
}

.username {
    font-size: 2.5rem;
    margin-bottom: 20px;
    color: var(--primary-color); /* Default color if none set in database */
}

#links-container {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.link-card {
    padding: 16px 20px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: var(--text-color);
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    border-radius: 12px;
    margin-bottom: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.link-card:last-child {
    margin-bottom: 0;
}

.link-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    filter: brightness(1.2);
}

.link-icon {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.2rem;
}

.link-icon .icon-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.link-title {
    font-size: 1.1rem;
    font-weight: 500;
}

footer {
    text-align: center;
    padding: 20px;
    color: var(--text-secondary);
    font-size: 0.9rem;
}

footer a {
    color: var(--primary-color);
    text-decoration: none;
    transition: opacity 0.2s ease;
}
`;

    return (
        <PageWrapper title='aioslink'>
            <Page>
                <div className="row mb-3">
                    <div className="col-md-4">
                        <Card>
                            <CardHeader>
                                <CardTitle>Site Configuration</CardTitle>
                            </CardHeader>
                            <CardBody>
                                <form>
                                    <div>
                                        <Label>Site Name</Label>
                                        <Input type="text" value={data.sitename || "Your Site Name"} readOnly={botPremiumStatus !== 2} />
                                    </div>
                                    <div>
                                        <Label>Profile Color</Label>
                                        <Input type="color" value={data.color} />
                                    </div>
                                    <div>
                                        <Label>Background Image (URL)</Label>
                                        <Input type="url" value={data.background} />
                                    </div>
                                    <div>
                                        <Label>Text Color</Label>
                                        <Input type="color" value={data.textcolor} />
                                    </div>
                                    <div>
                                        <Label>Profile Picture (URL)</Label>
                                        <Input type="url" value={data.profilepicture} />
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                    <div className="col-md-8">
                        <Card>
                            <CardHeader>
                                <CardTitle>Links</CardTitle>
                            </CardHeader>
                            <CardBody><></>
                                {/* Additional content can go here */}
                            </CardBody>
                        </Card>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        <Card style={{ width: '100%', aspectRatio: '16/9' }}>
                            <CardHeader>
                                <CardTitle>Preview</CardTitle>
                            </CardHeader>
                            <CardBody>
                                {/* Iframe to https.//aioslink.me/name and set everything from data as url params */}
                                <iframe src={`https://aioslink.me/${data.sitename}?background=${data.background}&profilepicture=${data.profilepicture}&color=${data.color}&textcolor=${data.textcolor}`} style={{ width: '100%', height: '100%', border: 'none' }}></iframe>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default AiosLinkPage;
