import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import AuthContext from '../../../contexts/authContext';
import { jwtDecode } from 'jwt-decode';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import Page from '../../../layout/Page/Page';
import Card, { CardBody } from '../../../components/bootstrap/Card';
import Button from '../../../components/bootstrap/Button';
import Icon from '../../../components/icon/Icon';

const SubscriptionSuccess = () => {
  const navigate = useNavigate();

  return (
    <PageWrapper title='Subscription Successful'>
      <Page className='h-100'>
        <div className='row h-100 align-items-center justify-content-center'>
          <div className='col-xl-4 col-lg-6 col-md-8 col-sm-10'>
            <Card className='shadow-3d-success'>
              <CardBody className='p-5'>
                <div className='d-flex flex-column align-items-center'>
                  <div className='display-1 text-success mb-4'>
                    <Icon icon='CheckCircle' />
                  </div>
                  <div className='h2 mb-4'>Thank You!</div>
                  <p className='h5 text-center mb-4'>
                    Your subscription has been successfully activated. You now have access to all the premium features!
                  </p>
                  <p className='h5 text-center mb-4'>
                    Reselect your bot to see the new features.
                  </p>
                  <Button
                    color='success'
                    className='w-100'
                    onClick={() => navigate('/subscription')}>
                    Return to Subscription Page
                  </Button>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </Page>
    </PageWrapper>
  );
};

export default SubscriptionSuccess;
